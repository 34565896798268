import styled from 'styled-components'

import BackgroundMobile from '../../assets/image/background-hero-arena-mrv-mobile.png'
import BackgroundMD from '../../assets/image/background-hero-arena-mrv-md.png'
import BackgroundLG from '../../assets/image/background-hero-arena-mrv-lg.png'
import BackgroundXL from '../../assets/image/background-hero-arena-mrv-xl.png'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-image: url(${BackgroundMobile});
  background-repeat: no-repeat;
  background-size: cover;
  height: 1211px;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
    height: 873px;
  }

  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
    height: 1262px;
  }

  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
    height: 1373px;
  }

  button, a {
    height: 48px;
    @media ${device.tablet} {
      width: 300px;
    }
  }

  .margin-logo {
    @media ${device.desktopLG} {
      margin-top: 150px;
    }

    @media ${device.desktopXL} {
      margin-top: 155px;
    }
  }
`
