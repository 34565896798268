import React from 'react'
import Img from 'gatsby-image'
import FlowerGraphism from '../../assets/image/flower-graphism.png'

import dataJson from './data/data.json'

import usePageQuery from '../../pageQuery'

import * as S from './styles'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IContaDigitalGratuitaProps {
  openModalOpenAccount: Function;
  setDataLayer: (state: IDataLayerParams) => void;
}

const SuperAppDoInter = ({ openModalOpenAccount, setDataLayer }: IContaDigitalGratuitaProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const benefitsDigitalAccount: string[] = dataJson

  const dataLayer: IDataLayerParams = {
    section: 'dobra_05',
    element_action: 'click button',
    section_name: 'Tudo o que você precisa no Super App do Inter',
    element_name: 'Abrir conta',
  }

  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row align-items-xl-center'>
          <div className='col-12 col-md-6 col-lg-5'>
            <div>
              <Img fluid={data.BannerSuperApp.fluid} alt='3 celulares com capa laranja e telas mostrando o Super App Inter' />
            </div>
          </div>
          <div className='col-12 col-md-6 offset-lg-1 pl-md-0 pr-xl-0'>
            <h2 className='fs-32 lh-40 fs-xl-40 lh-xl-50 fw-400 text-grayscale--500 mt-5 mt-md-0 mb-3 mb-md-5'>Tudo o que você precisa <span className='d-lg-block'>no Super App do Inter</span></h2>
            <div className='d-none d-md-block'>
              <S.DigitalAccountBenefits className=''>
                {benefitsDigitalAccount.map((text: string) => (
                  <div key={text} className='benefits d-flex align-items-center'>
                    <div className='mr-2'>
                      <img src={FlowerGraphism} alt='Icone Flower Graphism' />
                    </div>
                    <div>
                      <h3 className='fs-18 lh-22 fw-700 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                  </div>
              ))}
              </S.DigitalAccountBenefits>
            </div>
            <div className='d-none d-md-block mt-md-4'>
              <button
                title='Abrir conta'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
                onClick={() => {
                  openModalOpenAccount(true)
                }}
              >
                Abrir conta
              </button>
            </div>
          </div>
          <div className='col-12 d-md-none'>
            <S.DigitalAccountBenefits className='my-4'>
              {benefitsDigitalAccount.map((text: string) => (
                <div key={text} className='benefits d-flex align-items-center'>
                  <div className='mr-2'>
                    <img src={FlowerGraphism} alt='Icone Flower Graphism' />
                  </div>
                  <div>
                    <p
                      className='fs-18 lh-22 fw-700 text-grayscale--500 mb-0'
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  </div>
                </div>
              ))}
            </S.DigitalAccountBenefits>
          </div>
          <div className='col-12 d-md-none'>
            <div>
              <button
                title='Abrir conta'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
                onClick={() => {
                  openModalOpenAccount(true)
                  setDataLayer(dataLayer)
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    section_name: 'Tudo o que você precisa no Super App do Inter',
                    element_name: 'Abrir conta',
                  })
                }}
              >
                Abrir conta
              </button>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default SuperAppDoInter
