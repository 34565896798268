import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import ArenaMrvOpenAccountRightForm from 'src/components/UI/Forms/ArenaMrvOpenAccountForm'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import QrCodeArenaMrv from './assets/image/qrcode-arenamrv.jpg'
import pageContext from './pageContext.json'

import * as S from './style'

import {
  Hero,
  LojaDoGalo,
  SuperAppDoInter,
  IndiqueAmigos,
  GaloVerso,
} from './sections/_index'

const ExperienciaArenaMRV = () => {
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)
  const [ openModalOpenAccount, setOpenModalOpenAccount ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_02',
    element_action: 'click button',
    section_name: 'Cartão Inter Mastercard na Arena MRV',
    element_name: 'Abrir conta',
  })

  const socioTorcedorOpenAccountRightForm = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <ArenaMrvOpenAccountRightForm
        closeModal={() => setOpenModal(false)} dataLayer={{
          section: 'dobra_01',
          element_action: 'click button',
          section_name: 'Inter. Com você na Arena MRV',
          element_name: 'Cadastre-se e participe das experiências',
        }}
      />
    </Modal>
  )

  const openAccountRightForm = domReady && (
    <Modal isModalOpen={openModalOpenAccount} setIsModalOpen={setOpenModalOpenAccount} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setOpenModalOpenAccount(false)}
        dataLayer={dataLayer}
        customOrigin='Experiencia Arena MRV'
        qrBaixeApp={QrCodeArenaMrv}
        agoraBaixeOAppLink='https://intergo.app/703142f3'
      />
    </Modal>
  )

  return (
    <S.Wrapper>
      {socioTorcedorOpenAccountRightForm}
      {openAccountRightForm}
      <Layout pageContext={pageContext} handleHeaderOpenAccount={() => setOpenModalOpenAccount(true)}>
        <Hero openModal={setOpenModal} openModalOpenAccount={setOpenModalOpenAccount} setDataLayer={setDataLayer} />
        <GaloVerso />
        <LojaDoGalo />
        <SuperAppDoInter openModalOpenAccount={setOpenModalOpenAccount} setDataLayer={setDataLayer} />
        <IndiqueAmigos />
      </Layout>
    </S.Wrapper>
  )
}

export default ExperienciaArenaMRV
