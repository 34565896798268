import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import { useLocation } from '@reach/router'
import useWidth from 'src/hooks/window/useWidth'
import { getParameterByName, sendCDPFormData } from 'src/shared/helpers'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import MSG from './msgAccount.json'

import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'

import errorImg from './assets/images/error.png'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import BaixeOApp from './assets/images/qr-code-arena-mrv.png'

import { Container, CloseButton } from './style'

type SocioTorcedorOpenAccountRightFormProps = {
  closeModal: () => void;
  pageMei?: boolean;
  dataLayer: IDataLayerParams;
}

interface IFormValues {
  nome: string;
  email: string;
  telefone: string;
  cpfCnpj: string;
  dataNascimento: string;
}

function ArenaMrvOpenAccountRightForm ({ closeModal, pageMei, dataLayer }: SocioTorcedorOpenAccountRightFormProps) {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ sendDatalayerEvent ] = useDataLayer()
  const location = useLocation()
  const WIDTH_MD = 1024
  const windowWidth = useWidth(200)
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ message, setMessage ] = useState(MSG.PF)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)

  const SuccessMessageMEI = location.pathname.includes('/empresas/conta-digital/mei/')
  const publicOfferings = location.pathname.includes('/pra-voce/investimentos/ofertas-publicas')

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))

    if (SuccessMessageMEI) {
      setMessage(MSG.MEI)
    } if (publicOfferings) {
      setMessage(MSG.offers)
    }
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)

    const formData = {
      ...data,
      campanha: 'Experiencia Arena MRV',
      cpfCnpj: data.cpfCnpj.replace(/\D/g, ''),
      nome: data.nome,
      email: data.email,
      telefone: data.telefone.replace(/\D/g, ''),
      aceite: accept,

      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ])
      setLoading(false)
      setSent(true)
      sendCDPFormData({ formName: 'Formulario Experiencia Arena MRV', cpf: formData.cpfCnpj, email: formData.email })
      sendDatalayerEvent({
        section: `m_${dataLayer.section}`,
        element_action: 'submit',
        element_name: 'Continuar',
        element_previous: dataLayer.element_previous,
        section_name: dataLayer.section_name,
        step: 'success',
      })
    } catch (e) {
      setError(true)
      setLoading(false)
      sendDatalayerEvent({
        section: `m_${dataLayer.section}`,
        element_action: 'submit',
        element_name: 'Continuar',
        element_previous: dataLayer.element_previous,
        section_name: dataLayer.section_name,
      })
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
  }

  const asFilled = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_MD) {
      window.location.href = 'https://bancointer.go.link/?adj_t=12pmwdmw&adj_adgroup=brand_lab&adj_creative=arenamrv'
    } else {
      setSent(false)
    }
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
      <Close width='24' height='24' color={` ${pageMei ? 'grayscale--500' : 'orange--extra'}`} />
    </CloseButton>
  )

  return (
    <>
      { error && (
        <Container isModal={closeModal} className='d-flex align-items-center'>
          {closeButton}
          <div className='col-12 text-center'>
            <img src={errorImg} alt='Imagem de erro' width={64} height={64} />
            <h3 className='fs-24 lh-30 my-3 text-grayscale--500'>Tivemos um problema por aqui</h3>
            <hr />
            <p className='text-grayscale--400 fs-18 lh-22'>
              Verifique sua conexão ou tente novamente mais tarde.
            </p>

            <button
              onClick={handleReturn}
              title='Voltar'
              className='fs-16 fw-600 text-white text-none btn bg-orange--extra rounded-2 mx-auto mt-3 flex-end'
            >
              Voltar
            </button>
          </div>
        </Container>
      )}

      { sent && (
        <Container isModal={closeModal} className='d-flex align-items-center sent'>
          {closeButton}
          <div className='col-12 px-0'>
            <h3 className='fs-24 lh-30 fs-md-40 lh-md-50 text-grayscale--500 mb-4 fw-600'>
              <span className='text-orange--extra'>Inter</span>, com você na Arena MRV. Viva experiências únicas!
            </h3>
            <hr />
            <p className='fs-14 lh-16 fs-md-16 lh-md-20 text-grayscale--500 mb-4'>E se ainda não é cliente, abra sua conta</p>

            <div className='col-12 pb-2 mx-auto d-none d-md-block text-center mt-5'>
              <img src={BaixeOApp} alt='Baixe o App Inter' width={150} />
            </div>

            <div className='col-12 d-none d-md-block'>
              <hr />
              <p className='fs-14 lh-17 text-left'>
                Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o Super App do Inter.
              </p>
            </div>

            <button onClick={asFilled} className='fs-16 fw-600 text-white text-none btn bg-orange--extra rounded-2 mx-auto d-md-none mt-3'>
              Acessar o Super App
            </button>
          </div>
        </Container>
      )}

      <Container isModal={closeModal} className={`${error || sent ? 'd-none' : 'd-flex'} align-items-center`}>
        {closeButton}
        <div className='row'>
          <div className='col-12 py-4 pt-lg-0'>
            <h2 className='fs-22 lh-28 text-left mb-4 mt-5 text-grayscale--500 fw-600'>
              Cadastre-se e participe das experiências Inter Mastercard na Arena MRV
            </h2>
          </div>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='col-12 d-flex flex-column px-0  mb-3 pb-1'>
                  <label htmlFor='nome' className='fs-12 lh-15 fw-600'>Nome</label>
                  <input
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome'
                  />
                  {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0  mb-3 pb-1'>
                  <label htmlFor='telefone' className='fs-12 lh-15 fw-600'>Celular</label>
                  <input
                    ref={register({
                      required: 'Digite um telefone válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name='telefone'
                    id='telefone'
                    type='tel'
                    placeholder='Digite seu celular'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('telefone', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  {errors.telefone && <p className='fs-12 text-red--base mb-0 text-right'>{errors.telefone.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0  mb-3 pb-1'>
                  <label htmlFor='email' className='fs-12 lh-15 fw-600'>E-mail</label>
                  <input
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0  mb-3 pb-1'>
                  <label htmlFor='cpfCnpj' className='fs-12 lh-15 fw-600'>CPF/CNPJ</label>
                  <input
                    ref={register({
                      required: 'Insira um CPF ou CNPJ válido',
                      validate: {
                        isCpf: (value: string) => Validations.cpforcnpj(value) || 'CPF ou CNPJ Inválido',
                      },
                    })}
                    name='cpfCnpj'
                    id='cpfCnpj'
                    type='tel'
                    placeholder='Informe o CPF ou CNPJ'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpfCnpj', Masks.MaskCPFCNPJ(event.currentTarget.value))}
                  />
                  {errors.cpfCnpj && <p className='fs-12 text-red--base mb-0 text-right'>{errors.cpfCnpj.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0'>
                  <AcceptTerms accept={accept} setAccept={setAccept} name='conta-digital-pf' dataLayer={dataLayer} />
                </div>
                <div className='col-12 text-center'>
                  <button
                    type='submit' title='Enviar' disabled={!accept || loading}
                    className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                  >
                    {loading ? 'Enviando...' : 'Enviar'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ArenaMrvOpenAccountRightForm
