import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      HeroBannerArenaMRV: imageSharp(fluid: { originalName: { regex: "/hero-banner-arena-mrv/" }}) {
        fluid(maxWidth: 569, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      LogoArenaMrv: imageSharp(fluid: { originalName: { regex: "/logo-arena-mrv/" }}) {
        fluid(maxWidth: 228, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      BannerCartaoInter: imageSharp(fluid: { originalName: { regex: "/banner-cartao-inter/" }}) {
        fluid(maxWidth: 553, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      BannerSuperApp: imageSharp(fluid: { originalName: { regex: "/banner-super-app/" }}) {
        fluid(maxWidth: 458, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      LogoMaroon: imageSharp(fluid: { originalName: { regex: "/logo-maroon5/" }}) {
        fluid(maxWidth: 306, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      LogoMastercardMaroon: imageSharp(fluid: { originalName: { regex: "/logo-mastercard-maroon/" }}) {
        fluid(maxWidth: 228, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      LogoMaroonXL: imageSharp(fluid: { originalName: { regex: "/logo-maroon5-xl/" }}) {
        fluid(maxWidth: 526, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
