import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background: linear-gradient( #FF7A00 230px, #EFEEED 100px);
  
  @media ${device.tablet} {
    background: linear-gradient(to right, #EFEEED 65%, #FF7A00 50%);
  }

  @media ${device.desktopLG} {
    background: linear-gradient(to right, #EFEEED 60%, #FF7A00 50%);
  }

  @media ${device.desktopXL} {
    background: linear-gradient(to right, #EFEEED 62%, #FF7A00 50%);
  }

  .text {
    @media ${device.tablet} {
      bottom: 0;
      position: absolute;
      float: right;
      text-align: right;
      right: 0;
    }

    @media ${device.desktopLG} {
      bottom: 16px;
      position: absolute;
      float: right;
      text-align: right;
      right: 0;
    }
  }

  a {
    height: 48px;
    
    @media ${device.tablet} {
      width: 322px;
    }

    @media ${device.desktopLG} {
      width: 314px;
    }

    @media ${device.desktopXL} {
      width: 319px;
    }
  }
`
