import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

import { Modal } from 'src/components/Modal'
import PopupWhatsapp from 'src/components/PopupWhatsapp'
import WhatsappBottomSheet from 'src/components/WhatsappBottomSheet'

import * as S from './styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const IndiqueAmigos = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const buttonTitle = 'Convocar a galera'
  const whatsappMessage = 'Aproveite a parceria Inter e Arena MRV e venha simplificar a vida com uma Conta Digital gratuita, cartão sem anuidade, pré-vendas exclusivas e acúmulo de pontos. Baixe o Super App: https://bancointer.go.link/?adj_t=12pmwdmw&adj_adgroup=brand_lab&adj_creative=arenamrv'

  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ bottomSheet, setBottomSheet ] = useState(false)

  const dataLayer: IDataLayerParams = {
    section: 'dobra_06',
    section_name: 'Chame seus amigos para aproveitar os benefícios Inter + Arena MRV',
    element_action: 'click button',
    element_name: 'Compartilhar via whatsapp',
  }

  const windowWidth = useWidth(300)
  const domReady = useDomReady()
  const WIDTH_MD = 768

  const handleClick = () => {
    sendDatalayerEvent({
      section: 'dobra_06',
      section_name: 'Deixe seu time mais completo na Arena',
      element_action: 'click button',
      element_name: buttonTitle,
    })
    if (windowWidth >= WIDTH_MD) {
      setIsPopupOpen(!isPopupOpen)
    } else {
      setBottomSheet(!bottomSheet)
    }
  }

  const PopupExitPage = domReady && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen} locationToRender={document.body}>
      <PopupWhatsapp whatsappMessage={whatsappMessage} closeModal={() => setIsPopupOpen(false)} title='Chame seus amigos para aproveitar os benefícios Inter + Arena MRV' subTitle='Você pode compartilhar esse link com quantos amigos quiser' dataLayer={dataLayer} />
    </Modal>
  )

  const BottomSheetMobile = domReady && (
    <Modal isModalOpen={bottomSheet} setIsModalOpen={setBottomSheet} locationToRender={document.body}>
      <WhatsappBottomSheet whatsappMessage={whatsappMessage} title='Chame seus amigos para aproveitar os benefícios Inter + Arena MRV' subTitle='Você pode compartilhar esse link com quantos amigos quiser' dataLayer={dataLayer} />
    </Modal>
  )

  return (
    <S.Section className='py-5'>
      {PopupExitPage}
      {BottomSheetMobile}
      <S.ContainerCampoFutebol className='container h-100'>
        <div className='row h-100 align-content-lg-center'>
          <div className='col-12 text-center'>
            <div className='d-lg-flex justify-content-lg-center align-items-lg-center mx-md-auto'>
              <div>
                <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fw-400 text-white mb-4'>Deixe seu time mais completo na <span className='d-md-block'>Arena</span></h2>
                <p className='fs-18 lh-22 text-white mb-3'>Indique amigos torcedores e aproveite ainda mais a parceria <span className='d-md-block'>Inter e Arena MRV.</span></p>

                <div className='py-2'>
                  <a
                    onClick={handleClick}
                    title={buttonTitle}
                    className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
                  >
                    {buttonTitle}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </S.ContainerCampoFutebol>
    </S.Section>
  )
}

export default IndiqueAmigos
