import React from 'react'

import { Section } from './styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const LojaDoGalo = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 bg-grayscale--500 d-xl-flex align-items-xl-center' role='img' aria-label='Tela de celular com a Loja do Galo aberta na tela e várias camisas do atlético para comprar.'>
      <div className='container'>
        <div className='row align-items-lg-center'>
          <div className='col-12 col-md-7 col-lg-6 offset-md-5 offset-lg-6 pr-md-0'>
            <h2 className='fs-32 lh-40 fs-xl-40 lh-xl-50 fw-400 text-white mb-4'>Loja do Galo + Inter</h2>
            <p className='fs-18 lh-22 text-white pb-3'>Parceria com preços imperdíveis e sorteios de ingressos na Arena MRV, além de muitos outros prêmios.</p>
            <div className='mt-4'>
              <a
                href='https://intergo.app/b17d8a41'
                target='_blank'
                rel='noreferrer'
                title='Conheça a Loja do Galo'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    section_name: 'Loja do Galo + Inter',
                    element_name: 'Conheça a Loja do Galo',
                    redirect_url: 'https://intergo.app/b17d8a41',
                  })
                }}
              >
                Conheça a Loja do Galo
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default LojaDoGalo
