import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import BackgroundMobile from '../../assets/image/hero-banner-logo-galo.png'
import BackgroundMD from '../../assets/image/hero-banner-logo-galo-md.png'
import BackgroundLG from '../../assets/image/hero-banner-logo-galo-lg.png'
import BackgroundXL from '../../assets/image/hero-banner-logo-galo-xl.png'

export const Section = styled.section`
  background-image: url(${BackgroundMobile});
  background-repeat: no-repeat;
  background-size: contain;

  height: 366px;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
  }

  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
    height: 400px;
    background-size: cover;
  }

  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
    height: 666px;
  }

  button, a {
    height: 48px;

    @media ${device.tablet} {
      width: 250px;
    }
  }
`
