import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import * as S from './styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface IHeroProps {
  openModal: Function;
  openModalOpenAccount: Function;
  setDataLayer: (state: IDataLayerParams) => void;
}

const Hero = ({ openModal, openModalOpenAccount, setDataLayer }: IHeroProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_02',
    element_action: 'click button',
    section_name: 'Cartão Inter Mastercard na Arena MRV',
    element_name: 'Abrir conta',
  }

  return (
    <S.Section
      className='py-5'
      role='img'
      aria-label='Arena MRV vista do alto com símbolo do Atlético simbolizando o estádio do galo.'
    >
      <div className='container mb-md-5 mt-lg-5 pt-lg-5 mt-xl-3 pt-xl-0'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-xl-7 my-5 my-md-0 d-none d-md-block'>
            <div>
              <Img
                fluid={data.HeroBannerArenaMRV.fluid}
                alt='Celular com a tela do Super App do Inter ao lado do cartão mastercard inter.'
              />
            </div>
          </div>
          <div className='col-12 col-md-6  col-xl-5 order-md-last'>
            <h1 className='fs-32 lh-40 fs-lg-48 lh-lg-60 fw-400 text-white mb-3'>
              Inter. Com você <span className='d-lg-block'>na Arena MRV.</span>
            </h1>
            <p className='fs-18 lh-22 fw-400 text-white mb-4'>
              Baixe o Super App e aproveite experiências e oportunidades exclusivas.
            </p>
            <div>
              <button
                title='Participe das experiências'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
                onClick={() => {
                  openModal(true)
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    section_name: 'Inter. Com você na Arena MRV',
                    element_name: 'Participe das experiências',
                  })
                }}
              >
                Participe das experiências
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='container margin-logo'>
        <div className='row'>
          <div className='col-12 mt-5 my-md-0'>
            <div>
              <Img
                fluid={data.LogoArenaMrv.fluid}
                alt='Logo Mastercard mais a logo do Inter'
                className='mx-auto'
              />
            </div>
            <div className='col-12 my-5 my-md-0 d-md-none'>
              <div>
                <Img
                  fluid={data.HeroBannerArenaMRV.fluid}
                  alt='Celular com a tela do Super App do Inter ao lado do cartão mastercard inter.'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container pt-md-5'>
        <div className='row'>
          <div className='col-12 col-md-8 col-lg-6'>
            <h2 className='fs-32 lh-40 fs-xl-40 lh-xl-50 fw-400 text-white mb-4'>
              Cartão Mastercard Inter <span className='d-md-block'>na Arena MRV</span>
            </h2>
            <p className='fs-18 lh-22 fw-400 text-white mb-4'>
              Com o cartão Mastercard Inter você tem  <span className='fw-700'>pré-venda exclusiva</span> para eventos no estádio.
            </p>
            <p className='fs-18 lh-22 fw-400 text-white mb-4'>E você ainda ganha benefícios exclusivos no
              <a
                href='https://inter.co/pra-voce/cartoes/programa-de-pontos/'
                target='_blank'
                rel='noreferrer'
                className='text-orange--extra fw-700'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    section_name: 'Cartão Mastercard Inter na Arena MRV',
                    element_name: 'Loop',
                    redirect_url: 'https://inter.co/pra-voce/cartoes/programa-de-pontos/',
                  })
                }}
              >
                {' '}Loop
              </a>
              , o programa de pontos do Inter com vantagens que voltam pra você.
            </p>
            <div className='py-2'>
              <button
                onClick={() => {
                  openModalOpenAccount(true)
                  setDataLayer(dataLayer)
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    section_name: 'Cartão Inter Mastercard na Arena MRV',
                    element_name: 'Abrir conta',
                  })
                }}
                title='Abrir conta'
                className='btn btn-orange--extra rounded-2 text-none text-md-center'
              >
                Abrir conta
              </button>
            </div>
          </div>
          <div className='col-md-4 col-lg-6 d-none d-md-block mt-md-5'>
            <div>
              <Img
                fluid={data.BannerCartaoInter.fluid}
                alt='Imagem da frente e do verso do cartão mastercard inter laranja.'
              />
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
