import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import BackgroundMobile from '../../assets/image/background-chama-galera.png'
import BackgroundMD from '../../assets/image/background-chama-galera-md.png'
import BackgroundLG from '../../assets/image/background-chama-galera-lg.png'
import BackgroundXL from '../../assets/image/background-chama-galera-xl.png'

import BackgroundCampoFutebol from '../../assets/image/bg-campo-futebol-xl.png'

export const Section = styled.section`
  background-image: url(${BackgroundMobile});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
  }

  @media ${device.desktopLG} {
    height: 564px;
    background-image: url(${BackgroundLG});
    background-size: contain;
  }

  @media ${device.desktopXL} {
    height: 721px;
    background-image: url(${BackgroundXL});
  }

  @media ${device.desktopXXXL} {
    height: 1200px;
    background-image: url(${BackgroundXL});
    background-size: cover
  }

  button, a {
    height: 48px;
    @media ${device.tablet} {
      width: 178px;
    }
  }
`

export const ContainerCampoFutebol = styled.div`
  @media ${device.desktopXL} {
    background-image: url(${BackgroundCampoFutebol});
    background-repeat: no-repeat;
    background-position: center center;
  }
`
